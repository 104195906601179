import React from 'react'
import Navbarinner from "./Navbarinner"
import Video from "../Video/Video"
import ReactPlayer from 'react-player'
import Form from "../Form/Form"
import { Link } from 'gatsby'

const Navbarnew = (props) => (
        <section class="hero xmas-tree-bg is-transparent is-medium">

            <div classname="hero-overlay">


                    <div class="hero-head">
                        <nav
                        aria-label="main-navigation" 
                        className={props.sticky ? 'navbar is-fixed-top has-shadow' : 'navbar is-primary'}>
                            <Navbarinner />
                        </nav> 
                    </div>

                    {/* <figure class="react-video-container"> */}

                        {/* <ReactPlayer 
                            className="react-video-object is-hidden-touch" 
                            url='https://www.youtube.com/watch?v=P2TfIPVofPo' 
                            playing="true"
                            muted="true"
                            volume="0"
                            loop="true"
                            width="auto"
                            height="auto"
                            style={{  }}
                        /> */}

                    {/* </figure> */}

                    {/* <figure class="image is-16by9">
                        <iframe class="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/bkTw5R7gMZ4?controls=0" frameborder="0" allowfullscreen></iframe>
                    </figure> */}

                    {/* <div className="hero-video">
                        <Video
                            videoSrcURL="https://www.youtube.com/embed/P2TfIPVofPo"
                            videoTitle="Experience the holidays with Santa Walt!"
                        />
                    </div> */}

                    <div class="hero-body" style={{ backgroundColor: "rgba(26, 36, 71, 0.76)"}}>
                        <div class="container">
                            <div className="columns is-4-touch hero-card">
                                <div className="column is-2 is-size-1-touch"></div>
                                <div className="column is-2">
                                    <div className="sale-badge has-text-white is-pulled-right is-hidden-touch">
                                        <span>On Sale</span>
                                        <div className="date-border"></div>
                                        <span>Nov. 5th</span>
                                    </div>
                                    <div className="sale-badge has-text-white is-hidden-desktop">
                                        <span>On Sale</span>
                                        <div className="date-border"></div>
                                        <span>Nov. 5th</span>
                                    </div>
                                </div>
                                <div className="column is-7">
                                    <h1 className="title is-1 is-size-2-touch is-size-2-mobile is-spaced has-text-white">Experience a virtual visit with Santa Walt!</h1>
                                    <p className="title is-4 is-size-5-touch is-size-5-mobile is-spaced has-text-white">
                                        IT'S SAFE, SUPER-FUN, AFFORDABLE, AND PERSONALIZED.
                                    </p>
                                    {/* <p  className="subtitle is-size-6-touch is-size-6-mobile has-text-white">
                                        Join our mailing list to receive updates and special offers!​
                                    </p> */}

                                    <Link to="/packages/" style={{ maxWidth: "100%", minWidth: "300px" }} className="button is-primary is-large is-link">
                                        Book Now
                                    </Link>

                                    {/* <Form /> */}
                                    
                                </div>
                                <div className="column is-1 is-size-2-touch"></div>
                            </div>
                        </div>
                    </div>

                    <div className="hero-overlay"></div>

            </div>

        </section>
    )

export default Navbarnew