import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
// import { Waypoint } from 'react-waypoint';
import Navbarnew from "../components/Navbar/Navbarnew";
import Ticker from "../components/Ticker/Ticker"
import Footer from "../components/Footer/Footer"
import "./styles.scss";

export default class MainLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    const { children } = this.props;
    return (
      <div id="wrapper" className="app home has-navbar-fixed-top">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
        </Helmet>
        {/* <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint> */}
        <Navbarnew sticky={this.state.stickyNav} />
        <div className="app-body">
          {/* <Ticker /> */}
          {children}
        </div>
        <Footer />
      </div>
    );
  }
}
